const read_queue: ''[] = [];
const save_queue: ''[] = [];

function save_in_progress() {
  return save_queue.length > 0;
}

function read_or_save_in_progress() {
  return save_in_progress() || read_queue.length > 0;
}

function confirmOnPageExit(e) {
  e = e || window.event;
  const message =
    'Warning: We are currently processing some requests, you may have unsaved changes';

  // For IE6-8 and Firefox prior to version 4
  if (e) {
    e.returnValue = message;
  }
  // For Chrome, Safari, IE8+ and Opera 12+
  return message;
}

function update_state() {
  document.body.classList.toggle('pushed-state', read_or_save_in_progress());
  if (save_in_progress()) {
    window.onbeforeunload = confirmOnPageExit;
  } else {
    window.onbeforeunload = null;
  }
}

export function starting_read_request() {
  read_queue.push('');
  update_state();
}

export function starting_save_request() {
  save_queue.push('');
  update_state();
}

export function ended_read_request() {
  read_queue.pop();
  update_state();
}

export function ended_save_request() {
  save_queue.pop();
  update_state();
}

export function failed_save_request() {
  ended_save_request();
  const systemErrorMessages = document.getElementsByClassName(
    'system_errormessage',
  );
  for (const el of Array.from(systemErrorMessages)) {
    el.classList.add('error');
  }
}

export function saving() {
  return save_in_progress();
}
