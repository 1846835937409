import * as Sentry from '@sentry/react';
import type { TSchema } from '@sinclair/typebox';
import type { TypeCheck } from '@sinclair/typebox/compiler';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  ended_read_request,
  ended_save_request,
  failed_save_request,
  starting_read_request,
  starting_save_request,
} from './network-state';

export const csrfTokenHeaderName = 'X-CSRF-Token';
export const pusherSessionIdHeaderName = 'X-PUSHER-SESSION-ID';

interface TokenHeaders {
  [csrfTokenHeaderName]?: string;
  [pusherSessionIdHeaderName]?: string;
}

export function getCsrfToken(): string | undefined {
  const tokenElement = document.querySelector<HTMLMetaElement>(
    'meta[name="csrf-token"]',
  );

  return tokenElement?.content;
}

export function getTokenHeaders(): TokenHeaders {
  const tokenHeaders: TokenHeaders = {};

  const token = getCsrfToken();

  if (token) {
    tokenHeaders[csrfTokenHeaderName] = token;
  }

  if (window.cvpartner?.subscriber) {
    tokenHeaders[pusherSessionIdHeaderName] =
      window.cvpartner.subscriber.session_id();
  }

  return tokenHeaders;
}

interface BaseFetchOptions {
  body?: Record<string, unknown> | FormData;
  safe?: boolean;
  timeout?: number;
}

interface FetchOptions extends BaseFetchOptions {
  plainText?: boolean;
}

type FetchMethod = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

function getRequestString(url: string, method: string) {
  const urlInError = new URL(url, window.location.href).pathname;

  return `${method} ${urlInError}`;
}

export class FetchError extends Error {
  readonly status: number;
  readonly statusText: string;
  readonly body: string | undefined;

  constructor(
    method: string,
    url: string,
    status: number,
    statusText: string,
    body: string | undefined,
  ) {
    const requestString = getRequestString(url, method);
    const formattedStatusText = statusText ? ` "${statusText}"` : '';

    super(
      `${requestString} failed with status ${status}${formattedStatusText}`,
    );

    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

export class FetchTimeout extends Error {
  constructor(method: FetchMethod, url: string, timeout: number) {
    super(`${getRequestString(url, method)} timed out after ${timeout}ms`);
  }
}

export class FetchNetworkError extends Error {
  constructor(method: FetchMethod, url: string, cause: unknown) {
    super(`${getRequestString(url, method)} got an error`, { cause });
  }
}

export class FetchCancelError extends Error {
  constructor(method: FetchMethod, url: string) {
    super(`${getRequestString(url, method)} was cancelled`);
  }
}

interface TimeoutOptions {
  signal: AbortSignal | undefined;
  timeoutId: number | undefined;
}

interface InternalFetchOptions extends TimeoutOptions {
  url: string;
  method: FetchMethod;
  headers: HeadersInit;
  body: string | FormData | undefined;
  signal: AbortSignal | undefined;
  timeoutId: number | undefined;
}

interface InternalFetchBufferedOptions extends InternalFetchOptions {
  plainText: boolean;
}

async function callFetch({
  url,
  method,
  headers,
  body,
  signal,
  timeoutId,
}: InternalFetchOptions): Promise<Response> {
  try {
    return await fetch(url, { method, headers, body, signal });
  } catch (error) {
    throw new FetchNetworkError(method, url, error);
  } finally {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }
}

async function makeRequest(options: InternalFetchOptions) {
  const res = await callFetch(options);

  const { url, method } = options;

  if (!res.ok) {
    let body: string | undefined = undefined;

    try {
      body = await res.text();
    } catch {
      // ignore
    }

    throw new FetchError(method, url, res.status, res.statusText, body);
  }

  if (res.status === 204) {
    return null;
  }

  return res;
}

async function makeBufferedRequest(options: InternalFetchBufferedOptions) {
  const res = await makeRequest(options);

  if (!res) {
    return null;
  }

  // TODO: we should consider not waiting for the response body if it's ignored
  if (options.plainText) {
    return res.text();
  }

  return res.json();
}

function createTimeout(
  method: FetchMethod,
  url: string,
  timeout: FetchOptions['timeout'],
): TimeoutOptions & { controller: AbortController | undefined } {
  let signal: AbortSignal | undefined = undefined;
  let timeoutId: number | undefined = undefined;
  let controller: AbortController | undefined = undefined;

  if (timeout) {
    const actualController = new AbortController();
    controller = actualController;
    signal = controller.signal;

    timeoutId = window.setTimeout(() => {
      actualController.abort(new FetchTimeout(method, url, timeout));
    }, timeout);
  }

  return { signal, timeoutId, controller };
}

async function performFetch<T = unknown>(
  method: FetchMethod,
  url: string,
  options: FetchOptions = {},
): Promise<T> {
  const { timeout, plainText, safe } = options;

  let headers: HeadersInit = {
    ...getTokenHeaders(),
    accept: plainText ? 'text/plain' : 'application/json',
    // This is needed for Rails to detect that this is an XHR request
    'X-Requested-With': 'XMLHttpRequest',
  };

  let body: string | FormData | undefined = undefined;

  if (options.body instanceof FormData) {
    body = options.body;
  } else if (
    options.body &&
    (method === 'POST' || method === 'PUT' || method === 'PATCH')
  ) {
    headers['content-type'] = 'application/json';
    body = JSON.stringify(options.body);
  }

  const isReadRequest = method === 'GET' || safe;

  if (isReadRequest) {
    starting_read_request();
  } else {
    starting_save_request();
  }

  const { signal, timeoutId } = createTimeout(method, url, timeout);

  const request = makeBufferedRequest({
    url,
    method,
    headers,
    body,
    signal,
    timeoutId,
    plainText: plainText ?? false,
  });

  if (isReadRequest) {
    try {
      return await request;
    } finally {
      ended_read_request();
    }
  }

  try {
    const res = await request;

    ended_save_request();

    return res;
  } catch (error) {
    failed_save_request();

    throw error;
  }
}

function bindFetch(method: FetchMethod) {
  return async function fetch<T>(url: string, options?: FetchOptions) {
    const fetchResult = await performFetch<T>(method, url, options);

    return fetchResult;
  };
}

export const http = {
  get: bindFetch('GET'),
  post: bindFetch('POST'),
  patch: bindFetch('PATCH'),
  put: bindFetch('PUT'),
  delete: bindFetch('DELETE'),
};

function bindCheckedFetch(method: FetchMethod) {
  return async function checkedFetch<T extends TSchema>(
    url: string,
    checker: TypeCheck<T>,
    options?: BaseFetchOptions,
  ) {
    const fetchResult = await performFetch(method, url, options);

    return checker.Decode(fetchResult);
  };
}

export const httpChecked = {
  get: bindCheckedFetch('GET'),
  post: bindCheckedFetch('POST'),
  patch: bindCheckedFetch('PATCH'),
  put: bindCheckedFetch('PUT'),
  delete: bindCheckedFetch('DELETE'),
};

export async function ignoreNotFound<T>(promise: Promise<T | true>) {
  try {
    return await promise;
  } catch (error) {
    if (error instanceof FetchError && error.status === 404) {
      return true;
    } else {
      throw error;
    }
  }
}

interface StreamFetchOptions extends BaseFetchOptions {
  body: Record<string, unknown>;
}

const streamMethod = 'POST';

export class StreamingRequest {
  private readonly url: string;
  private readonly options: StreamFetchOptions;
  private shouldCancel = false;
  private resultString = '';
  private done = false;
  private hasStarted = false;
  private hasErrored = false;
  private controller: AbortController | undefined = undefined;
  private cancellationError: FetchCancelError | undefined = undefined;

  constructor(url: string, options: StreamFetchOptions) {
    this.url = url;
    this.options = options;

    makeObservable<
      StreamingRequest,
      | 'updateString'
      | 'done'
      | 'hasStarted'
      | 'hasErrored'
      | 'resultString'
      | 'shouldCancel'
      | 'url'
      | 'makeRequest'
      | 'options'
      | 'controller'
      | 'cancellationError'
    >(this, {
      error: true,
      isDone: computed,
      start: action,
      result: computed,
      cancel: action,
      isRunning: computed,
      updateString: action,
      done: observable,
      hasStarted: observable,
      hasErrored: observable,
      resultString: observable,
      shouldCancel: observable,
      makeRequest: false,
      url: false,
      options: false,
      controller: false,
      cancellationError: true,
    });
  }

  async start() {
    this.hasStarted = true;
    if (this.options.safe) {
      starting_read_request();
    } else {
      starting_save_request();
    }

    try {
      return await this.makeRequest();
    } catch {
      runInAction(() => {
        this.hasErrored = true;
      });
    } finally {
      if (this.options.safe) {
        ended_read_request();
      } else {
        // we don't care if it fails (so no `failed_save_request`)
        ended_save_request();
      }
    }
  }

  private async makeRequest() {
    const { signal, timeoutId, controller } = createTimeout(
      streamMethod,
      this.url,
      this.options.timeout,
    );

    this.controller = controller;

    const response = await makeRequest({
      url: this.url,
      method: streamMethod,
      headers: {
        ...getTokenHeaders(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(this.options.body),
      signal,
      timeoutId,
    });

    if (!response || !response.body) {
      runInAction(() => {
        this.hasErrored = true;
      });
      throw new Error('Did not get a response body when streaming');
    }

    const decoder = new TextDecoder();

    // Only Firefox and Node has async iterator support for ReadableStream :(
    // Chromium got it in 124, but Safari is still missing it.
    // Safari tracking bug: https://bugs.webkit.org/show_bug.cgi?id=194379
    // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#browser_compatibility
    // for await (const chunk of response.body) {
    //   this.resultString += decoder.decode(chunk.value);
    // }

    const reader = response.body.getReader();

    try {
      while (true) {
        const shouldCancel = runInAction(() => this.shouldCancel);

        if (shouldCancel) {
          await reader.cancel(this.cancellationError);
          break;
        }

        const chunk = await reader.read();

        if (chunk.done) {
          break;
        }

        const decodedChunk = decoder.decode(chunk.value);

        this.updateString(decodedChunk);
      }
    } catch (error) {
      Sentry.captureException(
        new Error('Got an error while streaming', { cause: error }),
      );
      runInAction(() => {
        this.hasErrored = true;
      });
    }

    reader.releaseLock();

    runInAction(() => {
      this.resultString = this.resultString.trimEnd();
      if (this.resultString.length === 0) {
        this.hasErrored = true;
      }
      this.done = true;
    });
  }

  private updateString(newString: string) {
    // the first chunk might start with whitespace
    if (this.resultString.length === 0) {
      this.resultString = newString.trimStart();
    } else {
      this.resultString += newString;
    }
  }

  cancel() {
    this.cancellationError = new FetchCancelError(streamMethod, this.url);
    this.controller?.abort(this.cancellationError);
    this.shouldCancel = true;
  }

  get result() {
    return this.resultString;
  }

  get isDone() {
    return this.done || this.shouldCancel || this.error;
  }

  get isRunning() {
    return this.hasStarted && !this.isDone;
  }

  get error() {
    return this.hasErrored;
  }
}
